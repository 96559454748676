'use strict'
const name = 'BgPullBack'
const properties = {
    hideOnStart: false,
    getMaxTravel(elementMeasure, viewportHeight) {
        return viewportHeight + elementMeasure.height
    },
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        },
        perspectiveParent: {
            type: 'element'
        },
        parallaxParent: {
            type: 'element'
        },
        viewportHeight: {
            type: 'number',
            min: 0
        },
        speedFactor: {
            type: 'number',
            default: 1
        }
    }
}

function register({factory}) {
    /**
     * @param {HTMLElement|HTMLElement[]} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters.
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {
        perspectiveParent,
        parallaxParent,
        viewportHeight = parallaxParent.offsetHeight,
        speedFactor = properties.schema.speedFactor.default,
        ...params
    } = {}) {
        const sequence = factory.sequence(params)
        const height = parallaxParent.offsetHeight
        const end = Math.min(height / (viewportHeight + height), 1)
        sequence.add([
            factory.animate('BaseBgZoom', elements, duration, delay, {
                perspectiveParent,
                speedFactor,
                out: {
                    start: 0,
                    end,
                    scale: 2,
                    ease: 'none'
                }
            }),
            factory.animate('BaseBgPositionY', elements, duration, delay, {
                from: -height / 4,
                to: 0,
                start: 0,
                end,
                ease: 'none'
            })
        ])

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
